import * as ReactDOM from 'react-dom';
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import CountUp from "react-countup";
import { slideInRight } from "react-animations";
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';
import Footer from "./footer";
import { Slide } from 'react-slideshow-image';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css';
import escudo from '../images/escudo.png';


function Home() {

    const SlideInRightBack = styled.div`animation: 1s ${keyframes`${slideInRight}`};`;
    const SlideInRightTitle = styled.div`animation: 1.5s ${keyframes`${slideInRight}`};`;
    const SlideInRightSub = styled.div`animation: 2s ${keyframes`${slideInRight}`};`;
    const SlideInRightButton = styled.div`animation: 3s ${keyframes`${slideInRight}`};`;

    const [cantidadPartidosTotal, setCantidadPartidosTotal] = useState([]);
    const [cantidadPartidosGanados, setCantidadPartidosGanados] = useState([]);
    const [cantidadPartidosPerdidos, setCantidadPartidosPerdidos] = useState([]);
    const [ultimoPartido, setUltimoPartido] = useState([]);
    const [unDiaComoHoy, setUnDiaComoHoy] = useState([]);
    const [busquedaFecha, setBusquedaFecha] = useState([]);
    const [clasesFecha, setClasesFecha] = useState([]);
    const [enAguada, setEnAguada] = useState([]);
    const [enAguadaGanados, setEnAguadaGanados] = useState([]);
    const [enAguadaPerdidos, setEnAguadaPerdidos] = useState([]);

    const [partidosJugador, setPartidosJugador] = useState([]);
    const [ptsJugador, setPuntosJugador] = useState([]);
    const [rtJugador, setRtJugador] = useState([]);
    const [astJugador, setAstJugador] = useState([]);

    const [minPromJugador, setMinutosPromJugador] = useState([]);
    const [ptsPromJugador, setPuntosPromJugador] = useState([]);
    const [rtPromJugador, setRtPromJugador] = useState([]);
    const [astPromJugador, setAstPromJugador] = useState([]);

    const [listadoJugadores, setListadoJugadores] = useState([]);
    const [listadoTecnicos, setListadoTecnicos] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        Promise.all([
            fetch('https://aguada-stats-api.vercel.app/cantidadPartidos'),
            fetch('https://aguada-stats-api.vercel.app/cantidadPartidos/ganados'),
            fetch('https://aguada-stats-api.vercel.app/cantidadPartidos/perdidos'),
            fetch("https://aguada-stats-api.vercel.app/ultimoPartido"),
            fetch("https://aguada-stats-api.vercel.app/diaComoHoy"),
            fetch("https://aguada-stats-api.vercel.app/cantidadPartidos/enAguada"),
            fetch("https://aguada-stats-api.vercel.app/cantidadPartidos/enAguadaGanados"),
            fetch("https://aguada-stats-api.vercel.app/cantidadPartidos/enAguadaPerdidos"),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/partidosJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/ptsJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/rtJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/astJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/minPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/ptsPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/rtPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/astPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/listado/jugadores'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/listado/tecnicos')
        ])
            .then(([resPartidosTotal, resPartidosGanados, resPartidosPerdidos, resUltimoPartido, resUnDiaComoHoy, resEnAguada, resEnAguadaGanados, resEnAguadaPerdidos, resPartidosJugador, resPtsJugador, resRtJugador, resAstJugador, resMinPromJugador, resPtsPromJugador, resAstPromJugador, resRtPromJugador, resListadoJugadores, resListadoTecnicos]) =>
                Promise.all([resPartidosTotal.json(), resPartidosGanados.json(), resPartidosPerdidos.json(), resUltimoPartido.json(), resUnDiaComoHoy.json(), resEnAguada.json(), resEnAguadaGanados.json(), resEnAguadaPerdidos.json(), resPartidosJugador.json(), resPtsJugador.json(), resRtJugador.json(), resAstJugador.json(), resMinPromJugador.json(), resPtsPromJugador.json(), resRtPromJugador.json(), resAstPromJugador.json(), resListadoJugadores.json(), resListadoTecnicos.json()])
            )
            .then(([dataPartidosTotal, dataPartidosGanados, dataPartidosPerdidos, dataUltimoPartido, dataUnDiaComoHoy, dataEnAguada, dataEnAguadaGanados, dataEnAguadaPerdidos, dataPartidosJugador, dataPtsJugador, dataRtJugador, dataAstJugador, dataMinPromJugador, dataPtsPromJugador, dataAstPromJugador, dataRtPromJugador, dataListadoJugadores, dataListadoTecnicos]) => {
                setCantidadPartidosTotal(dataPartidosTotal);
                setCantidadPartidosGanados(dataPartidosGanados);
                setCantidadPartidosPerdidos(dataPartidosPerdidos);
                setUltimoPartido(dataUltimoPartido);
                setUnDiaComoHoy(dataUnDiaComoHoy);
                setEnAguada(dataEnAguada);
                setEnAguadaGanados(dataEnAguadaGanados);
                setEnAguadaPerdidos(dataEnAguadaPerdidos);
                setPartidosJugador(dataPartidosJugador);
                setPuntosJugador(dataPtsJugador);
                setRtJugador(dataRtJugador);
                setAstJugador(dataAstJugador);
                setMinutosPromJugador(dataMinPromJugador);
                setPuntosPromJugador(dataPtsPromJugador);
                setRtPromJugador(dataRtPromJugador);
                setAstPromJugador(dataAstPromJugador);
                setListadoJugadores(dataListadoJugadores);
                setListadoTecnicos(dataListadoTecnicos);
                setIsLoading(true);
            });
        document.title = 'Aguada Stats';
    }, []);

    const buscarPorFecha = async (fecha) => {
        let date = new Date(fecha).toISOString();
        setIsLoading(true);
        let url = 'https://aguada-stats-api.vercel.app/fecha/' + date;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            setBusquedaFecha(result);
            const card = document.getElementById('cardFecha');
            const root = ReactDOM.createRoot(card);
            setClasesFecha(true);
            root.render(
                result.length === 1 ?
                    result.map(busqFecha =>
                        <>
                            <p className="text-center mt-1">{busqFecha.liga} - {busqFecha.temporadaRegular ? "Temp. Reg. Fecha " + busqFecha.fechaLiga :
                                busqFecha.playoffsEtapa ? busqFecha.playoffsEtapa + " Partido " + busqFecha.fechaLiga :
                                    busqFecha.superLiga ? "SuperLiga Fecha " + busqFecha.fechaLiga :
                                        busqFecha.liguilla ? "Liguilla Fecha " + busqFecha.fechaLiga :
                                            busqFecha.reclasificatorio ? "Reclasificatorio Fecha " + busqFecha.fechaLiga :
                                                busqFecha.fase + " Partido " + busqFecha.fechaLiga}</p>
                            <p className="text-center">{busqFecha.resultado}</p>
                            <p className="text-center">En {busqFecha.cancha} como {busqFecha.local ? 'Local' : 'Visitante'}</p>
                            <a href={`partido/${busqFecha._id}`} className='w-fit inline-block'>
                                <button className="rounded-full items-center bg-gradient-to-l from-green-800 to-green-700 text-md text-white shadow-md hover:scale-110 text-bold p-1 px-2 mt-1 block m-auto">
                                    Ver estadísticas
                                </button>
                            </a>
                        </>
                    )
                    : <p className="text-center my-10">No se jugaron partidos este día</p>
            );

        } finally {
            setIsLoading(true);
        }
    }

    return (
        <>
            {/* Hero Image */}
            <div className="bg-hero bg-center bg-no-repeat bg-cover h-screen overflow-hidden">
                {isLoading ?
                    <>
                        <SlideInRightBack>
                            <div className="backdrop-blur-md bg-white/1 w-full md:w-fit items-center md:float-right inline-block md:block mt-[40%] md:mt-20">
                                <div className="text-white text-center md:text-right px-10 md:px-32 py-5 xl:py-10">
                                    <SlideInRightTitle>
                                        <p className="text-2xl md:text-4xl lg:text-6xl xl:text-8xl 2xl:text-9xl font-bold">AGUADA</p>
                                        <p className="text-2xl md:text-4xl lg:text-6xl xl:text-8xl 2xl:text-9xl font-bold mb-4">STATS</p>
                                    </SlideInRightTitle>
                                    <SlideInRightSub>
                                        <p className="text-l md:text-md xl:text-xl">Estadísticas completas desde 2009</p>
                                        <p className="text-l md:text-md xl:text-xl">Jugadores, técnicos, campañas, torneos y más</p>
                                    </SlideInRightSub>
                                    <SlideInRightButton>
                                        <Link to='/buscarpartido'>
                                            <button className="rounded-full bg-gradient-to-l from-green-800 to-green-700 text-sm md:text-lg shadow-md hover:scale-110 text-bold p-2 md:p-3 mt-4">Buscar partido</button>
                                        </Link>
                                    </SlideInRightButton>
                                </div>
                            </div>
                        </SlideInRightBack>
                        <div className="w-screen flex flex-auto text-center pt-20 md:pt-10 bottom-0 mb-12 text-white absolute md:flex">
                            <div className="w-full md:w-1/3 md:text-6xl text-4xl">
                                <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold"><CountUp start={0} end={cantidadPartidosTotal} duration={3} /> </h1>
                                <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl">Jugados</h1>
                            </div>
                            <div className="w-full md:w-1/3 md:text-6xl text-4xl">
                                <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold"><CountUp start={0} end={cantidadPartidosGanados} duration={3} /> <span className="text-sm sm:text-lg md:text-xl font-normal">(<CountUp start={0.00} end={cantidadPartidosGanados / cantidadPartidosTotal * 100} duration={3} />%)</span></h1>
                                <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl">Ganados</h1>
                            </div>
                            <div className="w-full md:w-1/3 md:text-6xl text-4xl">
                                <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold"><CountUp start={0} end={cantidadPartidosPerdidos} duration={3} /> <span className="text-sm sm:text-lg md:text-xl font-normal">(<CountUp start={0.00} end={cantidadPartidosPerdidos / cantidadPartidosTotal * 100} duration={3} />%)</span></h1>
                                <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl">Perdidos</h1>
                            </div>
                        </div>
                    </>
                    :
                    <div className="fixed z-10 backdrop-blur-xl bg-white/20 p-10 rounded-md h-full w-full flex flex-col justify-center items-center">
                        <img src={escudo} alt="" className='loadingHome' />
                        <p className="text-center text-white text-lg font-semibold mt-6">CARGANDO DATOS...</p>
                    </div>
                }
            </div>

            <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed overflow-hidden pt-6">
                {/* Segmentos de cards */}
                <div className="flex flex-col md:flex-row flex-auto px-4 md:px-20">
                    <div className='text-center w-full md:w-2/5 my-2 md:my-6 md:mr-4'>
                        <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 rounded-md shadow-lg p-3 mb-4">
                            <h3 className="text-red-600 text-xl mb-2 font-semibold">Buscar jugador</h3>
                            <Autocomplete
                                id="input-jugadores"
                                options={listadoJugadores}
                                onChange={(event, value) => setTimeout(() => navigate('jugador/' + value), 250)}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <input type="text" {...params.inputProps} placeholder="Jugador" />
                                    </div>
                                )}
                                placeholder="Jugadores"
                                className="w-4/5 bg-green-100 py-1 rounded-lg border-2 border-green-600 m-auto"
                            />
                        </div>
                        <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 rounded-md shadow-lg p-3">
                            <h3 className="text-red-600 text-xl mb-2 font-semibold">Buscar técnico</h3>
                            <Autocomplete
                                id="input-tecnicos"
                                options={listadoTecnicos}
                                onChange={(event, value) => setTimeout(() => navigate('jugador/' + value), 250)}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <input type="text" {...params.inputProps} placeholder="Técnico" />
                                    </div>
                                )}
                                placeholder="Jugadores"
                                className="w-4/5 bg-green-100 py-1 rounded-lg border-2 border-green-600 m-auto"
                            />
                        </div>
                    </div>
                    <div className='text-center w-full md:w-3/5 my-2 md:my-6 md:ml-4'>
                        <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 rounded-md shadow-lg p-3">
                            <h3 className="text-red-600 text-xl mb-2 font-semibold">Último partido</h3>
                            {ultimoPartido.map(ultPart =>
                                <>
                                    <p className="text-center mt-3">{moment(ultPart.fecha).format('DD/MM/YYYY')}</p>
                                    <p className="text-center">{ultPart.liga} - {ultPart.temporadaRegular ? "Temp. Reg. Fecha " + ultPart.fechaLiga :
                                        ultPart.playoffsEtapa ? ultPart.playoffsEtapa + " Partido " + ultPart.fechaLiga :
                                            ultPart.superLiga ? "SuperLiga Fecha " + ultPart.fechaLiga :
                                                ultPart.liguilla ? "Liguilla Fecha " + ultPart.fechaLiga :
                                                    ultPart.reclasificatorio ? "Reclasificatorio Fecha " + ultPart.fechaLiga :
                                                        ultPart.fase + " Partido " + ultPart.fechaLiga}</p>
                                    <p className="text-center">{ultPart.resultado}</p>
                                    <p className="text-center">En {ultPart.cancha} como {ultPart.local ? 'Local' : 'Visitante'}</p>
                                    <a href={`partido/${ultPart._id}`} className='w-fit inline-block'>
                                        <button className="rounded-full items-center bg-gradient-to-l from-green-800 to-green-700 text-lg text-white shadow-md hover:scale-110 text-bold p-1 px-2 mt-3 block m-auto">
                                            Ver estadísticas
                                        </button>
                                    </a>

                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-auto px-4 md:px-20">
                    <div className='text-center w-full md:w-1/2 my-2 md:mr-4'>
                        <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 rounded-md shadow-lg p-3">
                            <h3 className="text-red-600 text-xl mb-2 font-semibold">Un día como hoy...</h3>
                            {unDiaComoHoy.length > 0 ?
                                <Slide>
                                    {unDiaComoHoy.map(diaComoHoy =>
                                        <>
                                            <div className="each-slide-effect inline-block font-normal">
                                                <p className="text-center mt-3">{moment(diaComoHoy.fecha).format('DD/MM/YYYY')}</p>
                                                <p className="text-center">{diaComoHoy.liga} - {diaComoHoy.temporadaRegular ? "Temp. Reg. Fecha " + diaComoHoy.fechaLiga :
                                                    diaComoHoy.playoffsEtapa ? diaComoHoy.playoffsEtapa + " Partido " + diaComoHoy.fechaLiga :
                                                        diaComoHoy.superLiga ? "SuperLiga Fecha " + diaComoHoy.fechaLiga :
                                                            diaComoHoy.liguilla ? "Liguilla Fecha " + diaComoHoy.fechaLiga :
                                                                diaComoHoy.reclasificatorio ? "Reclasificatorio Fecha " + diaComoHoy.fechaLiga :
                                                                    diaComoHoy.fase + " Partido " + diaComoHoy.fechaLiga}</p>
                                                <p className="text-center">{diaComoHoy.resultado}</p>
                                                <p className="text-center">En {diaComoHoy.cancha} como {diaComoHoy.local ? 'Local' : 'Visitante'}</p>
                                                <a href={`partido/${diaComoHoy._id}`} className='w-fit inline-block'>
                                                    <button className="rounded-full items-center bg-gradient-to-l from-green-800 to-green-700 text-lg text-white shadow-md hover:scale-110 text-bold p-1 px-2 mt-3 block m-auto">
                                                        Ver estadísticas
                                                    </button>
                                                </a>
                                            </div>
                                        </>
                                    )}
                                </Slide>
                                : <p className="text-center my-[4.18rem]">No se jugaron partidos un día como hoy</p>}
                        </div>
                    </div>
                    <div className='text-center w-full md:w-1/2 my-2 md:ml-4'>
                        <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 rounded-md shadow-lg p-3 h-full">
                            <h3 className="text-red-600 text-xl mb-2 font-semibold">Buscar por fecha</h3>
                            <input type="date" id="fechaBusq" className="w-4/5 bg-green-100 py-1 px-3 rounded-lg text-slate-400 border-2 border-green-600 md:mt-12"
                                style={{ marginBlock: clasesFecha.length === 0 ? '3.18rem' : '0' }}
                                onChange={(event, value) => buscarPorFecha(event.target.value)} />
                            <div id="cardFecha"></div>
                        </div>
                    </div>
                </div>

                {/* En Aguada */}
                <div className='bg-aguada bg-center bg-no-repeat bg-cover bg-fixed h-64 mt-10 overflow-hidden'>
                    <h1 className="text-center text-white font-bold text-4xl mt-8">En Aguada...</h1>
                    <div className="flex flex-auto text-center pt-5 text-white">
                        <div className="w-1/3 sm:text-6xl text-4xl">
                            <CountUp start={0} end={enAguada} duration={3} />
                            <h1 className="sm:text-3xl text-xl">Jugados</h1>
                        </div>
                        <div className="w-1/3 sm:text-6xl text-4xl">
                            <CountUp start={0} end={enAguadaGanados} duration={3} />
                            <span className="text-sm sm:text-lg md:text-xl font-normal">
                                (<CountUp start={0.00} end={enAguadaGanados / enAguada * 100} duration={3} />%)
                            </span>
                            <h1 className="sm:text-3xl text-xl">Ganados</h1>
                        </div>
                        <div className="w-1/3 sm:text-6xl text-4xl">
                            <CountUp start={0} end={enAguadaPerdidos} duration={3} />
                            <span className="text-sm sm:text-lg md:text-xl font-normal">
                                (<CountUp start={0.00} end={enAguadaPerdidos / enAguada * 100} duration={3} />%)
                            </span>
                            <h1 className="sm:text-3xl text-xl">Perdidos</h1>
                        </div>
                    </div>
                </div>

                {/* Récords */}
                <div className='px-4 md:px-20'>
                    <div className="backdrop-blur-md bg-white/50 inherit ml-minus w-100vw md:w-full md:block md:ml-0 text-gray-900 py-4 mt-10 mb-10 rounded-md">
                        <h1 className="text-center text-white text-2xl mb-4 font-semibold">Récords históricos (desde 2009)</h1>
                        <p className="text-center text-xl mb-2 font-semibold underline mt-8">Totales</p>
                        <div className="flex flex-wrap">
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center sm:border-r-2 sm:border-green-600">
                                <p className="text-lg font-semibold mb-2 m-auto">Partidos</p>
                                {partidosJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {partidosJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (partidosJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carrera.partidos}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center md:border-r-2 md:border-green-600">
                                <p className="text-lg font-semibold mb-2">Puntos</p>
                                {ptsJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {ptsJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (ptsJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carrera.pts}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center sm:border-r-2 sm:border-green-600">
                                <p className="text-lg font-semibold mb-2">Rebotes</p>
                                {rtJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {rtJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (rtJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carrera.rt}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center">
                                <p className="text-lg font-semibold mb-2">Asistencias</p>
                                {astJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {astJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (astJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carrera.ast}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className="text-center text-xl mb-2 font-semibold underline mt-8">Promedios</p>
                        <div className="flex flex-wrap">
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center sm:border-r-2 sm:border-green-600">
                                <p className="text-lg font-semibold mb-2">Minutos</p>
                                {minPromJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {minPromJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (minPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carreraProm.mpg}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center md:border-r-2 md:border-green-600">
                                <p className="text-lg font-semibold mb-2">Puntos</p>
                                {ptsPromJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {ptsPromJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (ptsPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carreraProm.ppg}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center sm:border-r-2 sm:border-green-600">
                                <p className="text-lg font-semibold mb-2">Rebotes</p>
                                {rtPromJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {rtPromJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (rtPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carreraProm.rtpg}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-full px-2 sm:w-1/2 md:w-1/4 mt-4 text-center">
                                <p className="text-lg font-semibold mb-2">Asistencias</p>
                                {astPromJugador.splice(3)}
                                <table className="w-full">
                                    <tbody>
                                        {astPromJugador.map((jugador, index) => <>
                                            <tr className={`text-l md:text-lg ${index < (astPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                <td className="w-1/6">{index + 1}</td>
                                                <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                <td className="w-1/6">{jugador.carreraProm.apg}</td>
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <a href={`records`}>
                            <button className="rounded-full items-center bg-gradient-to-l from-green-800 to-green-700 text-lg text-white shadow-md hover:scale-110 text-bold p-3 mt-10 block m-auto">
                                Ver más récords y máximos
                            </button>
                        </a>
                    </div>
                </div>

                <Footer></Footer>
            </div>

        </>
    );
}

export default Home;
