import React, { useState, useEffect } from "react";
import ReactLoading from 'react-loading';
import Footer from "./footer";
import star from "../images/star.png";
import silver from "../images/silver.png";
import bronze from "../images/bronze.png";
import Collapsible from 'react-collapsible';

function Planteles() {

    const [data, setData] = useState({ data: [] });
    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        Promise.all([
            fetch('https://aguada-stats-planteles-api.vercel.app/planteles')
        ])
            .then(([data]) =>
                Promise.all([data.json()])
            )
            .then(([data]) => {
                setData(data);
                setIsFetched(true);
            });
    }, []);


    useEffect(() => {
        document.title = 'Planteles';
    }, []);

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed min-h-screen px-4 md:px-20 pt-6">
            <h1 className="text-center mt-14 mb-8 text-3xl text-white font-semibold">Planteles</h1>
            <div className="backdrop-blur-md bg-gradient-to-l from-white/70 to-white/60 inherit mb-5 py-5 px-2 laptop:p-5 rounded-lg">
                {isFetched ?
                    <div className="block">
                        <div className="lg:flex items-center px-7 h-fit mb-4">
                            <div className="inline-block w-1/2 lg:w-1/6 pr-6 lg:pr-0 font-semibold text-center text-xl underline">Torneo</div>
                            <div className="inline-block w-1/2 lg:w-1/6 pl-6 lg:pl-0 font-semibold text-center text-xl underline">Posición</div>
                            <div className="hidden lg:inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl underline" style={{ width: '65%' }}>Plantel</div>
                        </div>
                        <div className="block lg:hidden">
                            {data.map((plantel, index) => plantel.mayores ?
                                <>
                                    <Collapsible trigger={(<div className="items-center h-fit mb-4">
                                        <div className="inline-block w-1/2 font-semibold text-center text-base cellphone:text-lg">{plantel.torneo}</div>
                                        <div className="inline-block w-1/2 font-semibold text-center text-base cellphone:text-lg px-4">{plantel.posicion}</div>
                                    </div>)} className="text-center text-xl font-semibold mt-6"
                                        openedClassName="text-center text-xl font-semibold mt-6"
                                        open={false}>
                                        <div className="text-center px-7 h-fit">
                                            <div className="block md:inline-block w-full px-6 mt-2 md:mt-0 text-left text-lg">
                                                <p>
                                                    <span className="font-semibold">Mayores: </span>
                                                    {plantel.mayores.map((plantelTorneo, index) =>
                                                        <>
                                                            <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 font-normal hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.mayores.length - 1) ? ',' : null} </span>
                                                        </>
                                                    )}
                                                </p>
                                                <p>
                                                    <span className="font-semibold">Extranjeros: </span>
                                                    {plantel.extranjeros.map((plantelTorneo, index) =>
                                                        <>
                                                            <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 font-normal hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.extranjeros.length - 1) ? ',' : null} </span>
                                                        </>
                                                    )}
                                                </p>
                                                {plantel.sub25.length > 0 ?
                                                    <>
                                                        <p>
                                                            <span className="font-semibold">Sub 25: </span>
                                                            {plantel.sub25.map((plantelTorneo, index) =>
                                                                <><a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 font-normal hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.sub25.length - 1) ? ',' : null} </span>
                                                                </>
                                                            )}
                                                        </p>
                                                    </>
                                                    : null}
                                                <p>
                                                    <span className="font-semibold">Sub 23: </span>
                                                    {plantel.sub23.map((plantelTorneo, index) =>
                                                        <>
                                                            <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 font-normal hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.sub23.length - 1) ? ',' : null} </span>
                                                        </>
                                                    )}
                                                </p>
                                                <p>
                                                    <span className="font-semibold">DT: </span>
                                                    {plantel.tecnicos.map((plantelTorneo, index) =>
                                                        <>
                                                            <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 font-normal hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.tecnicos.length - 1) ? ',' : null} </span>
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </Collapsible>
                                    {index < (data.length - 1) ? <> <hr className="my-4 border-black" /> </> : null}
                                </>
                                : null)}
                        </div>
                        <div className="hidden lg:block">
                            {data.map((plantel, index) => plantel.mayores ?
                                <>
                                    <div className="items-center px-7 h-fit flex justify-center align-center">
                                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl">{plantel.torneo}</div>
                                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                                            {plantel.estrella ?
                                                <div className="relative text-center cursor-default text-black w-fit m-auto">
                                                    {plantel.posicion === "CAMPEÓN" ?
                                                        <img src={star} alt="Estrella" className="w-12" />
                                                        : plantel.posicion === "Segundo" ?
                                                            <img src={silver} alt="Estrella" className="w-12" />
                                                            : <img src={bronze} alt="Estrella" className="w-12" />}
                                                    <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-l font-semibold">{plantel.estrella}</div>
                                                </div>
                                                : null}
                                            <p className="text-xl px-4">{plantel.posicion}</p>
                                            <p className="text-l font-normal">{plantel.resultadoFinal}</p>
                                        </div>
                                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                                            <p>
                                                <span className="font-semibold">Mayores: </span>
                                                {plantel.mayores.map((plantelTorneo, index) =>
                                                    <>
                                                        <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.mayores.length - 1) ? ',' : null} </span>
                                                    </>
                                                )}
                                            </p>
                                            <p>
                                                <span className="font-semibold">Extranjeros: </span>
                                                {plantel.extranjeros.map((plantelTorneo, index) =>
                                                    <>
                                                        <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.extranjeros.length - 1) ? ',' : null} </span>
                                                    </>
                                                )}
                                            </p>
                                            {plantel.sub25.length > 0 ?
                                                <>
                                                    <p>
                                                        <span className="font-semibold">Sub 25: </span>
                                                        {plantel.sub25.map((plantelTorneo, index) =>
                                                            <><a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.sub25.length - 1) ? ',' : null} </span>
                                                            </>
                                                        )}
                                                    </p>
                                                </>
                                                : null}
                                            <p>
                                                <span className="font-semibold">Sub 23: </span>
                                                {plantel.sub23.map((plantelTorneo, index) =>
                                                    <>
                                                        <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.sub23.length - 1) ? ',' : null} </span>
                                                    </>
                                                )}
                                            </p>
                                            <p>
                                                <span className="font-semibold">DT: </span>
                                                {plantel.tecnicos.map((plantelTorneo, index) =>
                                                    <>
                                                        <a href={`../jugador/` + plantelTorneo.nombre} className="hover:text-slate-500 hover:underline">{plantelTorneo.nombre}{plantelTorneo.observacion ? <span> ({plantelTorneo.observacion})</span> : null}</a><span>{index < (plantel.tecnicos.length - 1) ? ',' : null} </span>
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    {index < (data.length - 1) ? <> <hr className="my-4 border-black" /> </> : null}
                                </>
                                : null)}
                        </div>
                    </div>
                    :
                    <div className="backdrop-blur-xl bg-white/20 p-10 rounded-md">
                        <ReactLoading type="bars" color="#15803d" width={'10%'} className="text-center m-auto mb-16" />
                        <p className="text-center text-lg font-semibold">Buscando planteles...</p>
                    </div>
                }
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Planteles;
