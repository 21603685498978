import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from "moment";
import Footer from "./footer";
import profile from "../images/profileAvatar.jpg";
import tanktop from "../images/tank-top.png";
import star from "../images/star.png";
import silver from "../images/silver.png";
import bronze from "../images/bronze.png";

function Jugador() {

    const [data, setData] = useState({ data: [] });
    const [image, setImage] = useState({ data: "" });
    const [isFetched, setIsFetched] = useState(false);
    const [ligaExists, setLigaExists] = useState(false);
    const [internacionalExists, setInternacionalExists] = useState(false);
    const [ligaExistsDT, setLigaExistsDT] = useState(false);
    const [internacionalExistsDT, setInternacionalExistsDT] = useState(false);

    const [maxPuntos, setMaxPuntos] = useState([]);
    const [maxAsistencias, setMaxAsistencias] = useState([]);
    const [maxRebotes, setMaxRebotes] = useState([]);
    const [maxRobos, setMaxRobos] = useState([]);
    const [maxTapas, setMaxTapas] = useState([]);
    const [maxDobles, setMaxDobles] = useState([]);
    const [maxTriples, setMaxTriples] = useState([]);
    const [maxLibres, setMaxLibres] = useState([]);

    const [gamelog, setGamelog] = useState([]);
    const [gamelogDisplay, setGamelogDisplay] = useState(false);

    const [ultimosCinco, setUltimosCinco] = useState([]);

    const [tcDisplay, setTCDisplay] = useState(true);
    const [masMenosDisplay, setMasMenosDisplay] = useState(true);
    const [tapasRecDisplay, setTapasRecDisplay] = useState(true);

    const params = useParams();
    const nombreJugador = params.nombreJugador;

    useEffect(() => {
        Promise.all([
            fetch('https://aguada-stats-jugadores-api.vercel.app/jugador/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/puntos/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/asistencias/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/rebotesTot/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/robos/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/tapas/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/doblesHechos/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/triplesHechos/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/maximos/libresHechos/' + nombreJugador),
            fetch('https://aguada-stats-api.vercel.app/ultimosCinco/' + nombreJugador)
        ])

            .then(([data, resMaxPuntos, resMaxAsistencias, resMaxRebotes, resMaxRobos, resMaxTapas, resMaxDobles, resMaxTriples, resMaxLibres, resUltPart]) =>
                Promise.all([data.json(), resMaxPuntos.json(), resMaxAsistencias.json(), resMaxRebotes.json(), resMaxRobos.json(), resMaxTapas.json(), resMaxDobles.json(), resMaxTriples.json(), resMaxLibres.json(), resUltPart.json()])
            )
            .then(([data, dataMaxPuntos, dataMaxAsistencias, dataMaxRebotes, dataMaxRobos, dataMaxTapas, dataMaxDobles, dataMaxTriples, dataMaxLibres, dataUltPart]) => {
                setData(data);
                setMaxPuntos(dataMaxPuntos);
                setMaxAsistencias(dataMaxAsistencias);
                setMaxRebotes(dataMaxRebotes);
                setMaxRobos(dataMaxRobos);
                setMaxTapas(dataMaxTapas);
                setMaxDobles(dataMaxDobles);
                setMaxTriples(dataMaxTriples);
                setMaxLibres(dataMaxLibres);
                setUltimosCinco(dataUltPart);
                setImage('https://aguada-stats-imagenes-api.vercel.app/download/' + nombreJugador + '.jpg');
                setIsFetched(true);
            });
        document.title = nombreJugador;
    }, [nombreJugador]);

    function printPromediosHeaders(torneo) {
        let promediosHeaders =
            (<tr className='text-bold bg-gray-300'>
                <th className="w-28 px-12 border-r border-b border-black">{torneo}</th>
                <th className="w-28 px-4 border-r border-b border-black">Edad</th>
                <th className="w-28 px-4 border-r border-b border-black">Partidos</th>
                <th className="w-20 px-3 border-r border-b border-black">Ganados</th>
                <th className="w-24 px-4 border-r border-b border-black">Perdidos</th>
                <th className="w-24 px-4 border-r border-b border-black">Gan %</th>
                <th className="w-28 px-4 border-r border-b border-black">Min</th>
                <th className="w-20 px-3 border-r border-b border-black">Pts</th>
                <th className="w-28 px-3 border-r border-b border-black">RO</th>
                <th className="w-28 px-3 border-r border-b border-black">RD</th>
                <th className="w-28 px-3 border-r border-b border-black">RT</th>
                <th className="w-28 px-3 border-r border-b border-black">AS</th>
                <th className="w-20 px-3 border-r border-b border-black">TC H</th>
                <th className="w-20 px-3 border-r border-b border-black">TC L</th>
                <th className="w-20 px-3 border-r border-b border-black">TC%</th>
                <th className="w-28 px-0 border-r border-b border-black">2PTS H</th>
                <th className="w-28 px-0 border-r border-b border-black">2PTS L</th>
                <th className="w-28 px-4 border-r border-b border-black">2PTS%</th>
                <th className="w-28 px-0 border-r border-b border-black">3PTS H</th>
                <th className="w-28 px-0 border-r border-b border-black">3PTS L</th>
                <th className="w-28 px-4 border-r border-b border-black">3PTS%</th>
                <th className="w-28 px-0 border-r border-b border-black">1PTS H</th>
                <th className="w-28 px-0 border-r border-b border-black">1PTS L</th>
                <th className="w-28 px-4 border-r border-b border-black">1PTS%</th>
                <th className="w-28 px-3 border-r border-b border-black">PER</th>
                <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                <th className="w-28 px-3 border-r border-b border-black">FP</th>
            </tr>);
        return promediosHeaders
    };

    function printPromedios(torneoProm, i, z) {
        let promedios =
            (<tr className='text-center bg-gray-100 hover:bg-gray-200 border'>
                <th className="font-normal w-28 px-4 border-r border-b border-black underline cursor-pointer" onClick={() => getGamelog(nombreJugador, torneoProm.liga)}>{torneoProm.liga}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneoProm.liga.substring(torneoProm.liga.indexOf(' ') + 1).slice(0, 4) - (new Date(data[0].nacimiento).getFullYear())}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].partidos}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].ganados}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].perdidos}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].winPerc}</th>
                <th className="font-normal w-20 px-3 border-r border-b border-black">{(torneoProm.mpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.ppg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.ropg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.rdpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.rtpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.apg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tchpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tclpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.tcperc}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tdhpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tdlpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.tdperc}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tthpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.ttlpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.ttperc}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tlhpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tllpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.tlperc}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.topg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.spg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.bpg).toFixed(2)}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.fpg).toFixed(2)}</th>
            </tr>);
        return promedios
    };

    function printPromediosCarrera(jugador) {
        let promediosCarrera =
            (<tr className='text-bold bg-gray-300'>
                <th className="w-28 px-16 border-r border-b border-black">Carrera</th>
                <th className="w-28 px-4 border-r border-b border-black"></th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.partidos}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ganados}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.perdidos}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.winPerc}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.mpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.ppg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.ropg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.rdpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.rtpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.apg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tchpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tclpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.tcperc}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tdhpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tdlpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.tdperc}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tthpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.ttlpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.ttperc}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tlhpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tllpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.tlperc}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.topg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.spg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.bpg).toFixed(2)}</th>
                <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.fpg).toFixed(2)}</th>
            </tr>);
        return promediosCarrera
    };

    function printTotalesHeaders(torneo) {
        let totalesHeaders =
            (<tr className='text-bold bg-gray-300'>
                <th className="w-28 px-12 border-r border-b border-black">{torneo}</th>
                <th className="w-28 px-4 border-r border-b border-black">Edad</th>
                <th className="w-28 px-4 border-r border-b border-black">Min</th>
                <th className="w-20 px-3 border-r border-b border-black">Pts</th>
                <th className="w-28 px-3 border-r border-b border-black">RO</th>
                <th className="w-28 px-3 border-r border-b border-black">RD</th>
                <th className="w-28 px-3 border-r border-b border-black">RT</th>
                <th className="w-28 px-3 border-r border-b border-black">AS</th>
                <th className="w-20 px-0 border-r border-b border-black">TC H</th>
                <th className="w-20 px-0 border-r border-b border-black">TC L</th>
                <th className="w-32 px-0 border-r border-b border-black">2PTS H</th>
                <th className="w-28 px-0 border-r border-b border-black">2PTS L</th>
                <th className="w-28 px-0 border-r border-b border-black">3PTS H</th>
                <th className="w-28 px-0 border-r border-b border-black">3PTS L</th>
                <th className="w-28 px-0 border-r border-b border-black">1PTS H</th>
                <th className="w-28 px-0 border-r border-b border-black">1PTS L</th>
                <th className="w-28 px-3 border-r border-b border-black">PER</th>
                <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                <th className="w-28 px-3 border-r border-b border-black">FP</th>
                <th className="w-32 px-0 border-r border-b border-black">DBL-DBL</th>
            </tr>);
        return totalesHeaders
    };

    function printTotales(torneo) {
        let totales =
            (<tr className='text-center bg-gray-100 hover:bg-gray-200 border'>
                <th className="font-normal w-28 px-4 border-r border-b border-black underline cursor-pointer" onClick={() => getGamelog(nombreJugador, torneo.liga)}>{torneo.liga}</th>
                <th className="font-normal w-20 px-3 border-r border-b border-black">{torneo.liga.substring(torneo.liga.indexOf(' ') + 1).slice(0, 4) - (new Date(data[0].nacimiento).getFullYear())}</th>
                <th className="font-normal w-20 px-3 border-r border-b border-black">{torneo.min}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.pts}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.ro}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.rd}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.rt}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.ast}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tch}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tcl}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tdh}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tdl}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tth}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.ttl}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tlh}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tll}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.to}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.stl}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.bls}</th>
                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.fls}</th>
                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.dobleDoble ?
                    torneo.dobleDoble.ptsReb && torneo.dobleDoble.ptsAst ?
                        torneo.dobleDoble.ptsReb + torneo.dobleDoble.ptsAst
                        : torneo.dobleDoble.ptsReb ?
                            torneo.dobleDoble.ptsReb
                            : torneo.dobleDoble.ptsAst ?
                                torneo.dobleDoble.ptsAst
                                : 0
                    : 0}</th>
            </tr>);
        return totales
    };

    function printTotalesCarrera(jugador) {
        let totalesPromedio =
            (<tr className='text-bold bg-gray-300'>
                <th className="w-28 px-16 border-r border-b border-black">Carrera</th>
                <th className="w-28 px-4 border-r border-b border-black"></th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.min}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.pts}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ro}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.rd}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.rt}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ast}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tch}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tcl}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tdh}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tdl}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tth}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ttl}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tlh}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tll}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.to}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.stl}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.bls}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.fls}</th>
                <th className="w-28 px-6 border-r border-b border-black">{jugador.carrera.dobleDoble ?
                    jugador.carrera.dobleDoble.ptsReb && jugador.carrera.dobleDoble.ptsAst ?
                        jugador.carrera.dobleDoble.ptsReb + jugador.carrera.dobleDoble.ptsAst
                        : jugador.carrera.dobleDoble.ptsReb ?
                            jugador.carrera.dobleDoble.ptsReb
                            : jugador.carrera.dobleDoble.ptsAst ?
                                jugador.carrera.dobleDoble.ptsAst
                                : 0
                    : 0}</th>
            </tr>);
        return totalesPromedio
    };

    function printTotalesDTHeaders(torneo) {
        let totalesDTHeaders =
            (<tr className='text-bold bg-gray-300'>
                <th className="w-28 px-12 border-r border-b border-black">{torneo}</th>
                <th className="w-20 px-4 border-r border-b border-black">Edad</th>
                <th className="w-28 px-4 border-r border-b border-black">Partidos</th>
                <th className="w-20 px-3 border-r border-b border-black">Ganados</th>
                <th className="w-24 px-4 border-r border-b border-black">Perdidos</th>
                <th className="w-24 px-4 border-r border-b border-black">Gan %</th>
            </tr>);
        return totalesDTHeaders
    };

    function printTotalesDT(torneo) {
        let totalesDT =
            (<tr className='text-center bg-gray-100 hover:bg-gray-200 border'>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.liga}</th>
                <th className="font-normal w-20 px-4 border-r border-b border-black">{torneo.liga.substring(torneo.liga.indexOf(' ') + 1).slice(0, 4) - (new Date(data[0].nacimiento).getFullYear())}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.partidos}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.ganados}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.perdidos}</th>
                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.winPerc}</th>
            </tr>);
        return totalesDT
    };

    function printTotalesCarreraDT(jugador) {
        let totalesCarreraDT =
            (<tr className='text-bold bg-gray-300'>
                <th className="w-28 px-16 border-r border-b border-black">Carrera</th>
                <th className="w-20 px-4 border-r border-b border-black"></th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.partidos}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ganados}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.perdidos}</th>
                <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.winPerc}</th>
            </tr>);
        return totalesCarreraDT
    };

    async function getGamelog(jugador, liga) {
        try {
            const response = await fetch('https://aguada-stats-api.vercel.app/gamelog/' + jugador + '/' + liga, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const gamelog = await response.json();
            setGamelog(gamelog);
        } finally {
            setGamelogDisplay(true);
        }
    }

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed min-h-screen px-4 md:px-10 pt-20">
            <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 inherit mb-5 pt-1 pb-3 px-4 rounded-lg">
                {isFetched ?
                    data.length === 0 ?
                        <p className="text-center text-2xl my-3">No existe el jugador que buscas</p>
                        : <>
                            <div className="flex flex-col md:flex-row flex-auto">
                                <img src={data[0].pendImage ? profile : image} alt="Imagen de jugador" className="flex-none w-72 h-72 mx-auto mt-6 md:mt-4 md:ml-0 rounded-md shadow-md" />
                                <div className="m-auto mt-4 md:ml-4 w-full backdrop-opacity-40 bg-gradient-to-r from-white/30 to-white/70 rounded-md shadow-md h-fit inline-block px-2">
                                    <div className=" bg-gradient-to-r from-green-600 to-green-500 text-white text-center p-2 mb-2 rounded-md shadow-md" style={{ marginInline: '-0.5rem' }}>
                                        <h1 className="text-2xl font-bold uppercase tracking-wider underline">{data[0].nombre}</h1>
                                    </div>
                                    {/* Datos */}
                                    <div className={`inline-table ${data.map(jugador => jugador.jugador ? 'w-full lg:w-1/2 pr-2' : 'w-full')}`}>
                                        {data.map(jugador => jugador.jugador ?
                                            <p className="font-semibold mt-1 w-fit">Altura: <span className="font-normal">{jugador.altura}</span></p>
                                            : null)}
                                        <p className="font-semibold mt-1 w-fit">Nacimiento: <span className="font-normal">{data[0].nacimiento ? moment(data[0].nacimiento).format('DD/MM/YYYY') + ", " + data[0].nacionalidad : "-"}</span></p>
                                        {data.map(jugador => jugador.jugador ?
                                            <p className="font-semibold mt-1 w-fit">Debut: <span className="font-normal">{moment(jugador.debut).format('DD/MM/YYYY')}</span></p>
                                            : null)}
                                        {data.map(jugador => jugador.jugador ?
                                            <p className="font-semibold mt-1 w-fit">Torneos: <span className="font-normal">{jugador.torneos.map((torneo, i) => (i ? ', ' : '') + torneo.liga)}</span></p>
                                            : null)}
                                        {data.map(jugador => !jugador.jugador ?
                                            <p className="font-semibold mt-1 w-fit">Debut DT: <span className="font-normal">{moment(jugador.debut).format('DD/MM/YYYY')}</span></p>
                                            : null)}
                                        {data.map(jugador => !jugador.jugador ?
                                            <p className="font-semibold mt-1 w-fit">Torneos DT: <span className="font-normal">{jugador.torneos.map((torneo, i) => (i ? ', ' : '') + torneo.liga)}</span></p>
                                            : null)}
                                        {/* Números */}
                                        <div className="block mb-2">
                                            {data.map(jugador => jugador.jugador ? jugador.numero.map(num => <>
                                                <div className="inline-block mt-4">
                                                    <div className="relative text-center cursor-default text-black w-fit px-4 ">
                                                        <img src={tanktop} alt="Camiseta" className="w-12 drop-shadow-lg" />
                                                        <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-xl font-semibold text-white mt-1">{num}</div>
                                                    </div>
                                                </div>
                                            </>) : null)}
                                        </div>
                                        {/* Logros */}
                                        <div className="block">
                                            {data.map(jugador => jugador.campeon ? jugador.campeon.map(num => <>
                                                <div className="inline-block">
                                                    <div className="relative text-center cursor-default text-black px-2 w-20">
                                                        <img src={star} alt="Oro" className="w-12 m-auto drop-shadow-lg" />
                                                        <div className="absolute top-1/2 left-1/2 -translate-y-[80%] -translate-x-1/2 text-sm font-semibold">{num}</div>
                                                        <div className="block text-sm font-semibold">CAMPEÓN</div>
                                                    </div>
                                                </div>
                                            </>) : null)}
                                            {data.map(jugador => jugador.segundo ? jugador.segundo.map(num => <>
                                                <div className="inline-block">
                                                    <div className="relative text-center cursor-default text-black px-2 w-20">
                                                        <img src={silver} alt="Plata" className="w-12 m-auto drop-shadow-lg" />
                                                        <div className="absolute top-1/2 left-1/2 -translate-y-[80%] -translate-x-1/2 text-sm font-semibold">{num}</div>
                                                        <div className="block text-sm font-semibold">Segundo</div>
                                                    </div>
                                                </div>
                                            </>) : null)}
                                            {data.map(jugador => jugador.tercero ? jugador.tercero.map(num => <>
                                                <div className="inline-block">
                                                    <div className="relative text-center cursor-default text-black px-2 w-20">
                                                        <img src={bronze} alt="Bronce" className="w-12 m-auto drop-shadow-lg" />
                                                        <div className="absolute top-1/2 left-1/2 -translate-y-[80%] -translate-x-1/2 text-sm font-semibold">{num}</div>
                                                        <div className="block text-sm font-semibold">Tercero</div>
                                                    </div>
                                                </div>
                                            </>) : null)}
                                        </div>
                                    </div>
                                    {data.map(jugador => jugador.jugador ?
                                        <div className="inline-table w-full lg:w-1/2 my-2 lg:my-0 lg:mb-2 backdrop-opacity-40 bg-gradient-to-r from-white/30 to-white/70 rounded-md shadow-md pb-2 px-2">
                                            <div className="bg-gradient-to-r from-green-600 to-green-500 text-white text-center p-1 mb-1 rounded-md shadow-md" style={{ marginInline: '-0.5rem' }}>
                                                <h1 className="text-lg font-bold tracking-wider">Máximos</h1>
                                            </div>
                                            <table className='text-sm w-full rounded-md'>
                                                <tr className="border-b border-slate-400 hover:bg-white/70">
                                                    <th className="w-2/5 font-normal">Puntos</th>
                                                    {maxPuntos[0].jugador[0].puntos !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxPuntos[0].jugador[0].puntos !== 0 ? maxPuntos[0].jugador[0].puntos : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxPuntos[0]._id}`}>{maxPuntos[0].liga} <br /> vs {maxPuntos[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                                <tr className="border-b border-slate-400 hover:bg-white/70">
                                                    <th className="font-normal">Asistencias</th>
                                                    {maxAsistencias[0].jugador[0].asistencias !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxAsistencias[0].jugador[0].asistencias !== 0 ? maxAsistencias[0].jugador[0].asistencias : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxAsistencias[0]._id}`}>{maxAsistencias[0].liga} <br /> vs {maxAsistencias[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                                <tr className="border-b border-slate-400 hover:bg-white/70">
                                                    <th className="w-2/5 font-normal">Rebotes</th>
                                                    {maxRebotes[0].jugador[0].rebotesTot !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxRebotes[0].jugador[0].rebotesTot !== 0 ? maxRebotes[0].jugador[0].rebotesTot : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxRebotes[0]._id}`}>{maxRebotes[0].liga} <br /> vs {maxRebotes[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                                <tr className="border-b border-slate-400 hover:bg-white/70">
                                                    <th className="w-2/5 font-normal">Robos</th>
                                                    {maxRobos[0].jugador[0].robos !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxRobos[0].jugador[0].robos !== 0 ? maxRobos[0].jugador[0].robos : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxRobos[0]._id}`}>{maxRobos[0].liga} <br /> vs {maxRobos[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                                <tr className="border-b border-slate-400 hover:bg-white/70">
                                                    <th className="w-2/5 font-normal">Tapas</th>
                                                    {maxTapas[0].jugador[0].tapas !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxTapas[0].jugador[0].tapas !== 0 ? maxTapas[0].jugador[0].tapas : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxTapas[0]._id}`}>{maxTapas[0].liga} <br /> vs {maxTapas[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                                <tr className="border-b border-slate-400 hover:bg-white/70">
                                                    <th className="w-2/5 font-normal">Dobles</th>
                                                    {maxDobles[0].jugador[0].doblesHechos !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxDobles[0].jugador[0].doblesHechos !== 0 ? maxDobles[0].jugador[0].doblesHechos : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxDobles[0]._id}`}>{maxDobles[0].liga} <br /> vs {maxDobles[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                                <tr className="border-b border-slate-400 hover:bg-white/70">
                                                    <th className="w-2/5 font-normal">Triples</th>
                                                    {maxTriples[0].jugador[0].triplesHechos !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxTriples[0].jugador[0].triplesHechos !== 0 ? maxTriples[0].jugador[0].triplesHechos : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxTriples[0]._id}`}>{maxTriples[0].liga} <br /> vs {maxTriples[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                                <tr className="hover:bg-white/70">
                                                    <th className="w-2/5 font-normal">Libres</th>
                                                    {maxLibres[0].jugador[0].libresHechos !== 0 ?
                                                        <>
                                                            <th className="w-1/5">{maxLibres[0].jugador[0].libresHechos !== 0 ? maxLibres[0].jugador[0].libresHechos : '-'}</th>
                                                            <th className="w-2/5 font-normal"><a className="underline" href={`../partido/${maxLibres[0]._id}`}>{maxLibres[0].liga} <br /> vs {maxLibres[0].adversario}</a></th>
                                                        </>
                                                        : <>
                                                            <th className="w-1/5">-</th>
                                                            <th className="w-2/5 font-normal">-</th>
                                                        </>
                                                    }
                                                </tr>
                                            </table>
                                        </div>
                                        : null)}
                                </div>
                            </div>
                            {/* Gamelog */}
                            {gamelogDisplay ?
                                // Mostrar gamelog
                                <>
                                    {gamelog.map(partido =>
                                        partido.jugador[0].camposHechos === undefined && tcDisplay === true ?
                                            setTCDisplay(false) : null
                                    )}
                                    {gamelog.map(partido =>
                                        partido.jugador[0].camposHechos === undefined && masMenosDisplay === true ?
                                            setMasMenosDisplay(false) : null
                                    )}
                                    {gamelog.map(partido =>
                                        partido.jugador[0].camposHechos === undefined && tapasRecDisplay === true ?
                                            setTapasRecDisplay(false) : null
                                    )}
                                    <button onClick={() => setGamelogDisplay(false)} type="button" className="bg-gradient-to-r from-green-600 to-green-500 text-white hover:ring-2 hover:ring-green-600 focus:ring-3 focus:outline-none focus:ring-green-600 rounded-md text-sm p-2 inline-flex items-center float-right mt-4">
                                        <i class='fas fa-arrow-left mr-3'></i> Atrás
                                    </button>
                                    <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l border-t border-r border-black rounded-t-md">Partidos {gamelog[0].liga}: </h1>
                                    <table className='text-sm w-full max-h-80 overflow-auto border border-black block rounded-md'>
                                        <thead className="sticky top-0">
                                            <tr className='text-bold bg-gray-200'>
                                                <th className="w-40 px-4 border-r border-b border-black">Adversario</th>
                                                <th className="w-28 px-4 border-r border-b border-black">Fecha</th>
                                                {gamelog[0].jugador[0].numero !== undefined ? <th className="w-12 px-3 border-r border-b border-black">N°</th> : null}
                                                <th className="w-28 px-4 border-r border-b border-black">Min</th>
                                                <th className="w-20 px-3 border-r-2 border-b border-black">Pts</th>
                                                {tcDisplay ?
                                                    <>
                                                        {gamelog[0].jugador[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-b border-black">LC</th> : null}
                                                        {gamelog[0].jugador[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-b border-black">LC%</th> : null}
                                                    </>
                                                    : null}
                                                <th className="w-24 px-4 border-r border-b border-black">2PTS</th>
                                                <th className="w-24 px-4 border-r-2 border-b border-black">2PTS%</th>
                                                <th className="w-24 px-4 border-r border-b border-black">3PTS</th>
                                                <th className="w-28 px-4 border-r-2 border-b border-black">3PTS%</th>
                                                <th className="w-28 px-4 border-r border-b border-black">1PTS</th>
                                                <th className="w-28 px-4 border-r-2 border-b border-black">1PTS%</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RO</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RD</th>
                                                <th className="w-28 px-3 border-r-2 border-b border-black">RT</th>
                                                <th className="w-28 px-3 border-r border-b border-black">AS</th>
                                                <th className="w-28 px-3 border-r border-b border-black">PER</th>
                                                <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                                                <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                                                {tapasRecDisplay ?
                                                    <>
                                                        {gamelog[0].jugador[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-b border-black">BR</th> : null}
                                                    </>
                                                    : null}
                                                <th className="w-28 px-3 border-r border-b border-black">FP</th>
                                                {gamelog[0].jugador[0].foulsRec !== undefined ? <th className="w-12 px-3 border-r border-b border-black">FR</th> : null}
                                                {masMenosDisplay ?
                                                    <>
                                                        {gamelog[0].jugador[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-b border-black">+/-</th> : null}
                                                    </>
                                                    : null}
                                                {gamelog[0].jugador[0].valor !== undefined ? <th className="w-12 px-3 border-r border-b border-black">EF</th> : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gamelog.map(partido =>
                                                <tr className='text-center bg-white hover:bg-white/70 border'>
                                                    <td className="border border-l-0 border-black"><a className="inline-block w-full underline" href={`../partido/${partido._id}`}>{partido.adversario}</a></td>
                                                    <td className="border px-2 border-black">{moment(partido.fecha).format('DD/MM/YYYY')}</td>
                                                    {partido.jugador[0].numero >= 0 ? <td className="border border-black">{partido.jugador[0].numero}</td> : null}
                                                    <td className="border border-black">{partido.jugador[0].minutos}</td>
                                                    <td className="border border-r-2 border-black">{partido.jugador[0].puntos}</td>
                                                    {tcDisplay ?
                                                        <>
                                                            {partido.jugador[0].camposHechos !== undefined ? <td className="border border-l-2 border-black">{partido.jugador[0].camposHechos}/{partido.jugador[0].camposIntent}</td> : null}
                                                            {partido.jugador[0].camposPercent !== undefined ? <td className="border border-r-2 border-black">{partido.jugador[0].camposPercent}%</td> : null}
                                                        </>
                                                        : null}
                                                    <td className="border border-black">{partido.jugador[0].doblesHechos}/{partido.jugador[0].doblesIntent}</td>
                                                    {partido.jugador[0].doblesPercent ? <td className="border border-r-2 border-black">{partido.jugador[0].doblesPercent}%</td> :
                                                        partido.jugador[0].doblesHechos > 0 ? <td className="border border-r-2 border-black">{Math.round((partido.jugador[0].doblesHechos / partido.jugador[0].doblesIntent).toFixed(2) * 100)}%</td> :
                                                            <td className="border border-r-2 border-black">0%</td>}
                                                    <td className="border border-black">{partido.jugador[0].triplesHechos}/{partido.jugador[0].triplesIntent}</td>
                                                    {partido.jugador[0].triplesPercent ? <td className="border border-r-2 border-black">{partido.jugador[0].triplesPercent}%</td> :
                                                        partido.jugador[0].triplesHechos > 0 ? <td className="border border-r-2 border-black">{Math.round((partido.jugador[0].triplesHechos / partido.jugador[0].triplesIntent).toFixed(2) * 100)}%</td> :
                                                            <td className="border border-r-2 border-black">0%</td>}
                                                    <td className="border border-black">{partido.jugador[0].libresHechos}/{partido.jugador[0].libresIntent}</td>
                                                    {partido.jugador[0].libresPercent ? <td className="border border-r-2 border-black">{partido.jugador[0].libresPercent}%</td> :
                                                        partido.jugador[0].libresHechos > 0 ? <td className="border border-r-2 border-black">{Math.round((partido.jugador[0].libresHechos / partido.jugador[0].libresIntent).toFixed(2) * 100)}%</td> :
                                                            <td className="border border-r-2 border-black">0%</td>}
                                                    <td className="border border-black">{partido.jugador[0].rebotesOff}</td>
                                                    <td className="border border-black">{partido.jugador[0].rebotesDef}</td>
                                                    <td className="border border-r-2 border-black">{partido.jugador[0].rebotesTot}</td>
                                                    <td className="border border-black">{partido.jugador[0].asistencias}</td>
                                                    <td className="border border-black">{partido.jugador[0].perdidas}</td>
                                                    <td className="border border-black">{partido.jugador[0].robos}</td>
                                                    <td className="border border-black">{partido.jugador[0].tapas}</td>
                                                    {tapasRecDisplay ?
                                                        <>
                                                            {partido.jugador[0].tapasRec !== undefined ? <td className="border border-black">{partido.jugador[0].tapasRec}</td> : null}
                                                        </>
                                                        : null}
                                                    <td className="border border-black">{partido.jugador[0].fouls}</td>
                                                    {partido.jugador[0].foulsRec !== undefined ? <td className="border border-black">{partido.jugador[0].foulsRec}</td> : null}
                                                    {masMenosDisplay ?
                                                        <>
                                                            {partido.jugador[0].masMenos !== undefined ? <td className="border border-black">{partido.jugador[0].masMenos}</td> : null}
                                                        </>
                                                        : null}
                                                    {partido.jugador[0].valor !== undefined ? <td className="border border-black">{partido.jugador[0].valor}</td> : null}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </>
                                :
                                // Mostrar global stats
                                <>
                                    {/* Promedios Jugador */}
                                    {data.map((jugador, i) =>
                                        jugador.jugador ?
                                            <>
                                                <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l border-t border-r border-black rounded-t-md">Promedios: </h1>
                                                <table className='text-sm w-full overflow-auto border border-black block rounded-md'>
                                                    <thead>
                                                        {ligaExists ? printPromediosHeaders("Liga") : null}
                                                    </thead>
                                                    {jugador.torneosProm.map((torneoProm, z) => <tbody>
                                                        {torneoProm.liga.includes("LUB") && !ligaExists ? setLigaExists(true) : null}
                                                        {torneoProm.liga.includes("LUB") ?
                                                            printPromedios(torneoProm, i, z) : null}
                                                    </tbody>
                                                    )}
                                                    {internacionalExists ? printPromediosHeaders("Internacional") : null}
                                                    {jugador.torneosProm.map((torneoProm, z) => <tbody>
                                                        {!torneoProm.liga.includes("LUB") && !internacionalExists ? setInternacionalExists(true) : null}
                                                        {!torneoProm.liga.includes("LUB") ?
                                                            printPromedios(torneoProm, i, z) : null}
                                                    </tbody>
                                                    )}
                                                    <tfoot>
                                                        {data.map((jugador, i) => jugador.jugador ?
                                                            printPromediosCarrera(jugador, i) : null
                                                        )}
                                                    </tfoot>
                                                </table>
                                            </>
                                            : null)}
                                    {/* Totales Jugador */}
                                    {data.map((jugador, i) =>
                                        jugador.jugador ?
                                            <>
                                                <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l border-t border-r border-black rounded-t-md">Totales: </h1>
                                                <table className='text-sm w-full overflow-auto border border-black block rounded-md'>
                                                    <thead>
                                                        {ligaExists ? printTotalesHeaders("Liga") : null}
                                                    </thead>
                                                    {jugador.torneos.map((torneo) => <tbody>
                                                        {torneo.liga.includes("LUB") && !ligaExists ? setLigaExists(true) : null}
                                                        {torneo.liga.includes("LUB") ?
                                                            printTotales(torneo) : null}
                                                    </tbody>
                                                    )}
                                                    {internacionalExists ? printTotalesHeaders("Internacional") : null}
                                                    {jugador.torneos.map((torneo) => <tbody>
                                                        {!torneo.liga.includes("LUB") && !internacionalExists ? setInternacionalExists(true) : null}
                                                        {!torneo.liga.includes("LUB") ?
                                                            printTotales(torneo) : null}
                                                    </tbody>
                                                    )}
                                                    <tfoot>
                                                        {data.map((jugador, i) => jugador.jugador ?
                                                            printTotalesCarrera(jugador) : null
                                                        )}
                                                    </tfoot>
                                                </table>
                                            </>
                                            : null)}
                                    {/* Técnico */}
                                    {data.map((jugador, i) =>
                                        !jugador.jugador ?
                                            <>
                                                <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l border-t border-r border-black rounded-t-md">DT: </h1>
                                                <table className='text-sm w-full md:w-fit overflow-auto border border-black block mb-2 rounded-md'>
                                                    <thead>
                                                        {ligaExistsDT ? printTotalesDTHeaders("Liga") : null}
                                                    </thead>
                                                    {jugador.torneos.map(torneo => <tbody>
                                                        {torneo.liga.includes("LUB") && !ligaExistsDT ? setLigaExistsDT(true) : null}
                                                        {torneo.liga.includes("LUB") ?
                                                            printTotalesDT(torneo) : null}
                                                    </tbody>
                                                    )}
                                                    {internacionalExistsDT ? printTotalesDTHeaders("Internacional") : null}
                                                    {jugador.torneos.map(torneo => <tbody>
                                                        {!torneo.liga.includes("LUB") && !internacionalExistsDT ? setInternacionalExistsDT(true) : null}
                                                        {!torneo.liga.includes("LUB") ?
                                                            printTotalesDT(torneo) : null}
                                                    </tbody>
                                                    )}
                                                    <tfoot>
                                                        {data.map((jugador, i) => !jugador.jugador ?
                                                            printTotalesCarreraDT(jugador) : null
                                                        )}
                                                    </tfoot>
                                                </table>
                                            </>
                                            : null)}
                                </>
                            }

                            {/* Últimos 5 partidos */}
                            {data.map((jugador, i) =>
                                jugador.jugador ?
                                    <>
                                        <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l border-t border-r border-black rounded-t-md">Últimos cinco partidos: </h1>
                                        <table className='text-sm w-full max-h-80 overflow-auto border border-black block rounded-md'>
                                            <thead className="sticky top-0">
                                                <tr className='text-bold bg-gray-200'>
                                                    <th className="w-40 px-12 border-r border-b border-black">Liga</th>
                                                    <th className="w-40 px-4 border-r border-b border-black">Adversario</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">Fecha</th>
                                                    {ultimosCinco[0].jugador[0].numero !== undefined ? <th className="w-12 px-3 border-r border-b border-black">N°</th> : null}
                                                    <th className="w-28 px-4 border-r border-b border-black">Min</th>
                                                    <th className="w-20 px-3 border-r-2 border-b border-black">Pts</th>
                                                    {tcDisplay ?
                                                        <>
                                                            {ultimosCinco[0].jugador[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-b border-black">LC</th> : null}
                                                            {ultimosCinco[0].jugador[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-b border-black">LC%</th> : null}
                                                        </>
                                                        : null}
                                                    <th className="w-24 px-4 border-r border-b border-black">2PTS</th>
                                                    <th className="w-24 px-4 border-r-2 border-b border-black">2PTS%</th>
                                                    <th className="w-24 px-4 border-r border-b border-black">3PTS</th>
                                                    <th className="w-28 px-4 border-r-2 border-b border-black">3PTS%</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">1PTS</th>
                                                    <th className="w-28 px-4 border-r-2 border-b border-black">1PTS%</th>
                                                    <th className="w-28 px-3 border-r border-b border-black">RO</th>
                                                    <th className="w-28 px-3 border-r border-b border-black">RD</th>
                                                    <th className="w-28 px-3 border-r-2 border-b border-black">RT</th>
                                                    <th className="w-28 px-3 border-r border-b border-black">AS</th>
                                                    <th className="w-28 px-3 border-r border-b border-black">PER</th>
                                                    <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                                                    <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                                                    {tapasRecDisplay ?
                                                        <>
                                                            {ultimosCinco[0].jugador[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-b border-black">BR</th> : null}
                                                        </>
                                                        : null}
                                                    <th className="w-28 px-3 border-r border-b border-black">FP</th>
                                                    {ultimosCinco[0].jugador[0].foulsRec !== undefined ? <th className="w-12 px-3 border-r border-b border-black">FR</th> : null}
                                                    {masMenosDisplay ?
                                                        <>
                                                            {ultimosCinco[0].jugador[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-b border-black">+/-</th> : null}
                                                        </>
                                                        : null}
                                                    {ultimosCinco[0].jugador[0].valor !== undefined ? <th className="w-12 px-3 border-r border-b border-black">EF</th> : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ultimosCinco.map(partido =>
                                                    <tr className='text-center bg-white hover:bg-white/70 border'>
                                                        <td className="border border-l-0 border-black">{partido.liga}</td>
                                                        <td className="border border-l-0 border-black">{partido.adversario}</td>
                                                        <td className="border px-2 border-black">{moment(partido.fecha).format('DD/MM/YYYY')}</td>
                                                        {partido.jugador[0].numero >= 0 ? <td className="border border-black">{partido.jugador[0].numero}</td> : null}
                                                        <td className="border border-black">{partido.jugador[0].minutos}</td>
                                                        <td className="border border-r-2 border-black">{partido.jugador[0].puntos}</td>
                                                        {tcDisplay ?
                                                            <>
                                                                {partido.jugador[0].camposHechos !== undefined ? <td className="border border-l-2 border-black">{partido.jugador[0].camposHechos}/{partido.jugador[0].camposIntent}</td> : null}
                                                                {partido.jugador[0].camposPercent !== undefined ? <td className="border border-r-2 border-black">{partido.jugador[0].camposPercent}%</td> : null}
                                                            </>
                                                            : null}
                                                        <td className="border border-black">{partido.jugador[0].doblesHechos}/{partido.jugador[0].doblesIntent}</td>
                                                        {partido.jugador[0].doblesPercent ? <td className="border border-r-2 border-black">{partido.jugador[0].doblesPercent}%</td> :
                                                            partido.jugador[0].doblesHechos > 0 ? <td className="border border-r-2 border-black">{Math.round((partido.jugador[0].doblesHechos / partido.jugador[0].doblesIntent).toFixed(2) * 100)}%</td> :
                                                                <td className="border border-r-2 border-black">0%</td>}
                                                        <td className="border border-black">{partido.jugador[0].triplesHechos}/{partido.jugador[0].triplesIntent}</td>
                                                        {partido.jugador[0].triplesPercent ? <td className="border border-r-2 border-black">{partido.jugador[0].triplesPercent}%</td> :
                                                            partido.jugador[0].triplesHechos > 0 ? <td className="border border-r-2 border-black">{Math.round((partido.jugador[0].triplesHechos / partido.jugador[0].triplesIntent).toFixed(2) * 100)}%</td> :
                                                                <td className="border border-r-2 border-black">0%</td>}
                                                        <td className="border border-black">{partido.jugador[0].libresHechos}/{partido.jugador[0].libresIntent}</td>
                                                        {partido.jugador[0].libresPercent ? <td className="border border-r-2 border-black">{partido.jugador[0].libresPercent}%</td> :
                                                            partido.jugador[0].libresHechos > 0 ? <td className="border border-r-2 border-black">{Math.round((partido.jugador[0].libresHechos / partido.jugador[0].libresIntent).toFixed(2) * 100)}%</td> :
                                                                <td className="border border-r-2 border-black">0%</td>}
                                                        <td className="border border-black">{partido.jugador[0].rebotesOff}</td>
                                                        <td className="border border-black">{partido.jugador[0].rebotesDef}</td>
                                                        <td className="border border-r-2 border-black">{partido.jugador[0].rebotesTot}</td>
                                                        <td className="border border-black">{partido.jugador[0].asistencias}</td>
                                                        <td className="border border-black">{partido.jugador[0].perdidas}</td>
                                                        <td className="border border-black">{partido.jugador[0].robos}</td>
                                                        <td className="border border-black">{partido.jugador[0].tapas}</td>
                                                        {tapasRecDisplay ?
                                                            <>
                                                                {partido.jugador[0].tapasRec !== undefined ? <td className="border border-black">{partido.jugador[0].tapasRec}</td> : null}
                                                            </>
                                                            : null}
                                                        <td className="border border-black">{partido.jugador[0].fouls}</td>
                                                        {partido.jugador[0].foulsRec !== undefined ? <td className="border border-black">{partido.jugador[0].foulsRec}</td> : null}
                                                        {masMenosDisplay ?
                                                            <>
                                                                {partido.jugador[0].masMenos !== undefined ? <td className="border border-black">{partido.jugador[0].masMenos}</td> : null}
                                                            </>
                                                            : null}
                                                        {partido.jugador[0].valor !== undefined ? <td className="border border-black">{partido.jugador[0].valor}</td> : null}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                    </>
                                    : null)}
                        </>
                    :
                    <div className="backdrop-blur-xl bg-white/20 p-10 rounded-md">
                        <ReactLoading type="bars" color="#15803d" width={'10%'} className="text-center m-auto mb-16" />
                        <p className="text-center text-lg font-semibold">Buscando jugador...</p>
                    </div>
                }

            </div>
            <Footer></Footer>
        </div>
    );
}

export default Jugador;
